import React from "react";
import styled from "styled-components";

const FlexSpacer = () => <Spacer />;

const Spacer = styled.div`
  flex-grow: 1;
`;

export default FlexSpacer;
